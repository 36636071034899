@import '../../global_styles.scss';

.Content {
  padding: 10px;
  h1,p {
    padding: 0;
    margin: 0 0 1em 0;
  }
  a, a:link {
    color: $c6;
  }
  a:visited {
    color:$c6;
  }
  a:hover {
    color: $c2;
  }
  pre {
    padding: 10px;
    border:1px solid $c5;
    background-color: $c1;
    font-weight: bold;
  }
  code {
    font-family: 'Courier New', Courier, monospace;
  }
}