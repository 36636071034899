@import '../../global_styles.scss';

.ThreadBig {
  display: flex;
  flex-direction: column;
  background-color: $c4;
  border: 1px solid $c5;
  .Title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $c5;
    padding: 10px;
    margin-bottom: 10px;
    color: $c3;
    .headline {
      font-size: 1.5em;
      width: 100%;
      a, a:link, a:visited {
        color: $c3;
      }
      a:hover, a:active {
        color: $c3;
      }
    }
    .info {
      font-size: 0.8em;
      color: $c3faded;
    }
  }
}
