@import '../../global_styles.scss';

.ContentBox-Middle {
  background-color: $c4;
  backdrop-filter: blur(10px);
  padding: 2em 1em;
  margin: 0 0 5px 0;
  border: 1px solid  $c5;
  div.Wrapper {
    margin: auto;
    width: 480px;
  }
}