@import '../../global_styles.scss';

.formRow {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 10px;
  padding: 0 10px;
  label {
    flex:2;
    text-align: left;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  input,
  textarea,
  select,
  .TextAreaPreview,
  .InputTags {
    flex:8;
    border:2px solid $c5;
    padding: 5px;
    background-color: $c5;
    color: $c3;
    font-size: 1em;
  }
  textarea {
    min-height: 200px;
  }
}
