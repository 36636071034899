body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #101213;
  color:#cecece;
  line-height: 1.4em;
  background-color: #1c1c1c;
  background-image: url(/static/media/studio.7b1a98b0.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
.pnlogo {
  background-image: url(/static/media/producer-network-logo-w.8c8487ce.svg);
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap; }

.PageNavi {
  background-color: #111111;
  margin-bottom: 10px; }
  .PageNavi nav {
    max-width: 924px;
    margin: auto;
    display: flex;
    font-size: 1em; }
    .PageNavi nav div.navElement {
      padding: 10px;
      margin: 0px 0px 0px 0; }
      .PageNavi nav div.navElement a, .PageNavi nav div.navElement a:link, .PageNavi nav div.navElement a:visited {
        color: #CCCCCC;
        text-decoration: none; }
      .PageNavi nav div.navElement a:hover, .PageNavi nav div.navElement a:active {
        color: #8dd32a;
        text-decoration: underline; }

.PageContent {
  max-width: 924px;
  margin: auto; }

.formRow {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 10px;
  padding: 0 10px; }
  .formRow label {
    flex: 2 1;
    text-align: left;
    padding-right: 10px;
    overflow: hidden;
    text-overflow: ellipsis; }
  .formRow input,
  .formRow textarea,
  .formRow select,
  .formRow .TextAreaPreview,
  .formRow .InputTags {
    flex: 8 1;
    border: 2px solid #333333;
    padding: 5px;
    background-color: #333333;
    color: #CCCCCC;
    font-size: 1em; }
  .formRow textarea {
    min-height: 200px; }

.Button {
  background-color: #8dd32a;
  color: #1a1a1a;
  font-weight: bold;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  border: 2px solid #333333;
  padding: 5px; }

.Content {
  padding: 10px; }
  .Content h1, .Content p {
    padding: 0;
    margin: 0 0 1em 0; }
  .Content a, .Content a:link {
    color: #8dd32a; }
  .Content a:visited {
    color: #8dd32a; }
  .Content a:hover {
    color: #CCCCCC; }
  .Content pre {
    padding: 10px;
    border: 1px solid #333333;
    background-color: #111111;
    font-weight: bold; }
  .Content code {
    font-family: 'Courier New', Courier, monospace; }

.ContentBox-Wide {
  background-color: #222222;
  padding: 1em 1em;
  margin: 0 0 10px 0;
  border: 1px solid #333333; }
  .ContentBox-Wide div.Wrapper {
    margin: auto;
    width: 100%; }

.ContentBox-Middle {
  background-color: #222222;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  padding: 2em 1em;
  margin: 0 0 5px 0;
  border: 1px solid #333333; }
  .ContentBox-Middle div.Wrapper {
    margin: auto;
    width: 480px; }

.Info {
  background: #8dd32a;
  color: #1a1a1a;
  padding: 5px; }

.ThreadSmall {
  display: flex;
  background-color: #222222;
  border: 1px solid #333333;
  border-top: 0; }
  .ThreadSmall .Title, .ThreadSmall .Replies, .ThreadSmall .Views, .ThreadSmall .LastReply {
    padding: 10px;
    color: #CCCCCC;
    margin: 1px; }
  .ThreadSmall .Title {
    display: flex;
    flex-direction: column;
    flex: 8 1;
    max-width: 540px;
    overflow: hidden;
    text-overflow: ellipsis; }
    .ThreadSmall .Title .headline {
      font-size: 1.5em;
      line-height: 1em; }
      .ThreadSmall .Title .headline a, .ThreadSmall .Title .headline a:link, .ThreadSmall .Title .headline a:visited {
        color: #CCCCCC;
        text-decoration: none; }
      .ThreadSmall .Title .headline a:hover, .ThreadSmall .Title .headline a:active {
        color: #8dd32a;
        text-decoration: underline; }
    .ThreadSmall .Title .subheader {
      color: #4e4e4e;
      font-size: 0.7em; }
    .ThreadSmall .Title .tags {
      font-size: 0.7em; }
      .ThreadSmall .Title .tags span {
        margin-right: 5px;
        background-color: #333333;
        color: #8dd32a;
        padding: 3px 4px;
        border-radius: 3px; }
  .ThreadSmall .Replies, .ThreadSmall .Views {
    flex: 1 1;
    max-width: 80px;
    text-align: center; }
  .ThreadSmall .LastReply {
    flex: 2 1;
    font-size: 0.7em;
    color: #CCCCCC; }

.ButtonBar {
  display: flex;
  margin-top: 5px;
  margin-bottom: 5px;
  flex-direction: row; }

.Spacer {
  width: 5px; }

.ReplyRow {
  display: flex;
  flex-direction: column;
  background-color: #222222;
  border: 1px solid #333333;
  border-top: 0; }
  .ReplyRow > .Content {
    flex: 5 1;
    margin-top: 0;
    padding-top: 0; }
  .ReplyRow .Title {
    padding: 5px 10px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    flex: 1 1;
    color: #4e4e4e;
    display: flex;
    flex-direction: row; }
    .ReplyRow .Title .info {
      flex: 5 1;
      font-size: 0.8em; }
    .ReplyRow .Title .actions {
      flex: 1 1;
      display: flex;
      flex-direction: row-reverse; }

.ReactPlayer {
  width: 100%;
  margin-bottom: 1em; }

.Markdown {
  width: 100%; }
  .Markdown img {
    max-width: 100%; }
  .Markdown p, .Markdown h1, .Markdown h2, .Markdown h3, .Markdown h4, .Markdown h5 {
    margin: 0;
    margin-bottom: 1em;
    line-height: 1em; }
  .Markdown p {
    line-height: 1.33em; }
  .Markdown a, .Markdown a:link, .Markdown a:visited {
    color: #CCCCCC; }
  .Markdown a:hover, .Markdown a:active {
    color: #CCCCCC; }

.ThreadBig {
  display: flex;
  flex-direction: column;
  background-color: #222222;
  border: 1px solid #333333; }
  .ThreadBig .Title {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #333333;
    padding: 10px;
    margin-bottom: 10px;
    color: #CCCCCC; }
    .ThreadBig .Title .headline {
      font-size: 1.5em;
      width: 100%; }
      .ThreadBig .Title .headline a, .ThreadBig .Title .headline a:link, .ThreadBig .Title .headline a:visited {
        color: #CCCCCC; }
      .ThreadBig .Title .headline a:hover, .ThreadBig .Title .headline a:active {
        color: #CCCCCC; }
    .ThreadBig .Title .info {
      font-size: 0.8em;
      color: #4e4e4e; }


.TextAreaPreview .panes {
  display: flex;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #CCCCCC; }
  .TextAreaPreview .panes .Markdown, .TextAreaPreview .panes textarea {
    flex: 1 1; }


.InputTags .react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border-radius: 1px;
  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;
  /* clicking anywhere will focus the input */
  cursor: text; }

.InputTags .react-tags.is-focused {
  border-color: #B1B1B1; }

.InputTags .react-tags__selected {
  display: inline; }

.InputTags .react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit; }

.InputTags .react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px; }

.InputTags .react-tags__selected-tag:hover,
.InputTags .react-tags__selected-tag:focus {
  border-color: #B1B1B1; }

.InputTags .react-tags__search {
  display: inline-block;
  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;
  /* prevent autoresize overflowing the container */
  max-width: 100%; }

.InputTags .react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;
  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  /* match the font styles */
  font-size: inherit;
  line-height: inherit; }

.InputTags .react-tags__search input::-ms-clear {
  display: none; }

.InputTags .react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; }

@media screen and (min-width: 30em) {
  .InputTags .react-tags__suggestions {
    width: 240px; } }

.InputTags .react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

.InputTags .react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px; }

.InputTags .react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600; }

.InputTags .react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee; }

.InputTags .react-tags__suggestions li.is-active {
  background: #b7cfe0; }

.InputTags .react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto; }

.Table td, .Table th {
  padding: 5px; }

