@import '../../global_styles.scss';

.Markdown {
  width: 100%;
  img {
    max-width: 100%;
  }
  p,h1,h2,h3,h4,h5 {
    margin: 0;
    margin-bottom: 1em;
    line-height: 1em;
  }
  p {
    line-height: 1.33em;
  }
  a, a:link, a:visited {
    color: $c3;
  }
  a:hover, a:active {
    color: $c3;
  }
}