@import '../../global_styles.scss';

.Button {
  background-color: $c6;
  color: $c7;
  font-weight: bold;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  border:2px solid $c5;
  padding: 5px;

}