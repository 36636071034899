@import '../../global_styles.scss';

.ThreadSmall {
  display: flex;
  background-color:$c4;
  border: 1px solid $c5;
  border-top: 0;
  .Title, .Replies, .Views, .LastReply {
    padding: 10px;
    color: $c3;
    margin: 1px;
  }
  .Title {
    display: flex;
    flex-direction: column;
    flex:8;
    max-width: 540px;
    overflow: hidden;
    text-overflow: ellipsis;
    .headline {
      font-size: 1.5em;
      line-height: 1em;
      a, a:link, a:visited {
        color: $c3;
        text-decoration: none;
      }
      a:hover, a:active {
        color: $c6;
        text-decoration: underline;
      }
    }
    .subheader {
      color: $c3faded;
      font-size: 0.7em;
    }
    .tags {
      font-size: 0.7em;
      span {
        margin-right: 5px;
        background-color: $c5;
        color: $c6;
        padding: 3px 4px;
        border-radius: 3px;
      }
    }
  }
  .Replies, .Views {
    flex:1;
    max-width: 80px;
    text-align: center;
  }
  .LastReply {
    flex:2;
    font-size: 0.7em;
    color: $c3;
  }
}
