@import '../../global_styles.scss';

.ReplyRow {
  display: flex;
  flex-direction: column;
  background-color: $c4;
  border: 1px solid $c5;
  border-top: 0;
  > .Content {
    flex: 5;
    margin-top: 0;
    padding-top: 0;
  }
  .Title {
    padding: 5px 10px;
    padding-bottom:0;
    display: flex;
    flex-direction: column;
    flex:1;
    color: $c3faded;
    display: flex;
    flex-direction: row;
    .info {
      flex:5;
      font-size: 0.8em;
    }
    .actions {
      flex:1;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}
