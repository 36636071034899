@import '../../global_styles.scss';

.TextAreaPreview {
  .panes {
    display: flex;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid $c3;
    .Markdown, textarea {
      flex: 1;
    }
  }
}