@import '../../global_styles.scss';

.ContentBox-Wide {
  background-color: $c4;
  padding: 1em 1em;
  margin: 0 0 10px 0;
  border: 1px solid $c5;
  div.Wrapper {
    margin: auto;
    width: 100%;
  }
}