@import '/global_styles.scss';
.pnlogo {
  background-image: url(img/producer-network-logo-w.svg);
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}
.PageNavi {
  background-color: $c1;
  margin-bottom: 10px;
  nav {
    max-width: 924px;
    margin: auto;
    display: flex;
    font-size: 1em;
    div.navElement {
    padding: 10px;
      margin: 0px 0px 0px 0;
      a, a:link, a:visited {
        color: $c2;
        text-decoration: none;
      }
      a:hover, a:active {
        color: $c6;
        text-decoration: underline;
      }
    }
  }
}
.PageContent{
  max-width: 924px;
  margin: auto;
}
